import React, { useRef } from 'react'
import { useInView } from 'framer-motion'
import Box from '@mui/system/Box'
import imgLeavesBg from "../../../images/leaves/backgroundLeaves.jpg"
import * as styles from "./leavesAnimation.module.css"
import imgLeaf1 from "../../../images/leaves/realLeaf1.png"
import imgLeaf2 from "../../../images/leaves/realLeaf2.png"
import imgLeaf3 from "../../../images/leaves/realLeaf3.png"
import imgLeaf4 from "../../../images/leaves/realLeaf4.png"
import * as stylesClay from "../../../styles/clay.module.css"


/*  
    Receives the lowest and highest values of a range and  
    returns a random integer that falls within that range.  
*/
function randomInteger(low, high) {
  return low + Math.floor(Math.random() * (high - low));
}

/*  
   Receives the lowest and highest values of a range and  
   returns a random float that falls within that range.  
*/
function randomFloat(low, high) {
  return low + Math.random() * (high - low);
}

/*  
    Receives a number and returns its CSS pixel value.  
*/
// function pixelValue(value) {
//   return value + 'px';
// }

function percValue(value) {
  return value + '%';
}

/*  
    Returns a duration value for the falling animation.  
*/

function durationValue(value) {
  return value + 's';
}


function getImage(randInt) {
  let srcImg
  switch (randInt) {
    case 1:
      srcImg = imgLeaf1
      break
    case 2:
      srcImg = imgLeaf2
      break
    case 3:
      srcImg = imgLeaf3
      break
    case 4:
      srcImg = imgLeaf4
      break
    default:
      break
  }
  return srcImg
}


const sxWrap = {
  "& .ball": {
    bgcolor: "primary2.main",
  },
  "& .ball2": {
    bgcolor: "primary.main",
  }
}

const Leaf = React.memo(({
  numberOfLeavesImages = 5,
  minSpinDuration = 4,
  maxSpinDuration = 6,
  minFadeAndDropDuration = 2,
  maxFadeAndDropDuration = 4,
  minDelay = 0,
  maxDelay = 2,
  minLeftPos = -5,
  maxLeftPos = 90,
  isInView
}) => {



  const randInt = randomInteger(1, numberOfLeavesImages)
  const srcImg = getImage(randInt)
  const leafLeftPos = percValue(randomInteger(minLeftPos, maxLeftPos));
  const imgSpinAnimationName = (Math.random() < 0.5) ? `${styles.clockwiseSpin}` : `${styles.counterclockwiseSpinAndFlip}`;
  const imgSpinDuration = durationValue(randomFloat(minSpinDuration, maxSpinDuration));
  const leafAnimationName = `${styles.fade}, ${styles.drop}`;
  const fadeAndDropDuration = durationValue(randomFloat(minFadeAndDropDuration, maxFadeAndDropDuration));
  const leafAnimationDuration = fadeAndDropDuration + ', ' + fadeAndDropDuration;
  const leafDelay = durationValue(randomFloat(minDelay, maxDelay));
  const leafAnimationDelay = leafDelay + ', ' + leafDelay;

  return (
    <Box
      className={stylesClay.clay}
      sx={sxWrap}
      style={{
        height: "30px",
        width: "30px",
        borderRadius: "50%",
        top: "-100px",
        left: leafLeftPos,
        animationName: isInView ? leafAnimationName : "",
        animationDuration: leafAnimationDuration,
        animationDelay: leafAnimationDelay,
      }}
    >
      <div
        className="ball"
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          height: "10px",
          width: "10px",
          borderRadius: "50%",
        }}
      />

      <img
        src={srcImg}
        className={styles.leafImg}
        style={{
          animationName: isInView ? imgSpinAnimationName : "",
          animationDuration: imgSpinDuration,
        }}
        alt=""
      />
    </Box>
  )
})



/** LeavesAnimation
 * Falling leaves to the ground
 */
const LeavesAnimation = ({ numberOfLeaves = 30, leavesAnimationClass = "leavesAnimationClass" }) => {

  const refWrap = useRef(null)
  const isInView = useInView(refWrap)
  return (
    <div
      ref={refWrap}
      className={`${leavesAnimationClass} ${styles.leafContWrap}`}
      style={{
        background: `#4E4226 url(${imgLeavesBg}) no-repeat top left`,
        height: "700px",
        backgroundSize: 'cover',
      }}
    >
      <div
        className={styles.leafContainer}
      >
        {
          Array(numberOfLeaves).fill(0).map((v, i) => {
            return <Leaf key={`leaf-${i}`} isInView={isInView} />
          })
        }
      </div>
    </div>)
}

export default LeavesAnimation

// <!– The container is dynamically populated using the init function in leaves.js –>
// <!– Its dimensions and position are defined using its id selector in leaves.css –>


// /** 
//     Uses an img element to create each leaf. “leavesAnimation.module.css” implements two spin   
//     animations for the leaves: clockwiseSpin and counterclockwiseSpinAndFlip. This  
//     function determines which of these spin animations should be applied to each leaf.  
// */
// function createALeaf(styles) {
//   /* Start by creating a wrapper div, and an empty img element */
//   var leafDiv = document.createElement('div');
//   var image = document.createElement('img');

//   /* Randomly choose a leaf image and assign it to the newly created element */

//   const randInt = randomInteger(1, 5)
//   let srcImg

//   srcImg = getImage(randInt, srcImg)
//   image.src = srcImg

//   leafDiv.style.top = "-100px";

//   /* Position the leaf at a random location along the screen */
//   leafDiv.style.left = pixelValue(randomInteger(0, 500));

//   /* Randomly choose a spin animation */
//   var spinAnimationName = (Math.random() < 0.5) ? `${styles.clockwiseSpin}` : `${styles.counterclockwiseSpinAndFlip}`;

//   /* Set the -webkit-animation-name property with these values */
//   leafDiv.style.animationName = `${styles.fade}, ${styles.drop}`;
//   image.style.animationName = spinAnimationName;

//   /* Figure out a random duration for the fade and drop animations */
//   var fadeAndDropDuration = durationValue(randomFloat(5, 11));

//   /* Figure out another random duration for the spin animation */
//   var spinDuration = durationValue(randomFloat(4, 8));
//   /* Set the -webkit-animation-duration property with these values */
//   leafDiv.style.animationDuration = fadeAndDropDuration + ', ' + fadeAndDropDuration;

//   var leafDelay = durationValue(randomFloat(0, 5));
//   leafDiv.style.animationDelay = leafDelay + ', ' + leafDelay;

//   image.style.animationDuration = spinDuration;
//   leafDiv.appendChild(image);

//   /* Return this img element so it can be added to the document */
//   return leafDiv;
// }


//   function init(idLeafContSelector) {
//     /* Get a reference to the element that will contain the leaves */

//     var container = document.getElementById(idLeafContSelector);
//     /* Fill the empty container with new leaves */
//     for (var i = 0; i < NUMBER_OF_LEAVES; i++) {
//       container.appendChild(createALeaf(styles));
//     }
//   }

